import { useUserStore } from '@/stores/index.js'
export function useAfterEach(router) {
  router.afterEach((to, from) => {
    next()
  });
}

const loginPath = '/login'

export function useBeforeEach(router) {

  router.beforeEach((to, from, next) => {
    const store = useUserStore()
    const auth = !!store.$state.userInfo
    // console.log([from.path, to.path, auth ? '已经授权' : '没有授权'])
    if (!auth) {
      if (to.path !== loginPath) {
        next({ path: loginPath })
      } else {
        next()
      }
    } else {

      if (to.path !== loginPath) {
        next()
      } else {
        next({ path: '/' })
      }
    }







  });
}

const locks = {
  localstorage_cacheParams_cleared: false
};

/* 根据条件清除页面缓存 */
function clearPagesLocalstorageCache(to, from) {
  if (from.path === '/') {
    if (!locks.localstorage_cacheParams_cleared) {
      window.localStorage.setItem('localstorage_cacheParams', '{}');
      window.localStorage.setItem('localstorage_cacheAts', '{}');
      locks.localstorage_cacheParams_cleared = true;
    }
    return;
  }
  const localstorage_cacheParams = JSON.parse(localStorage.getItem('localstorage_cacheParams'));

  if (!localstorage_cacheParams[to.path]) return;
  if (!Object.keys(localstorage_cacheParams[to.path]).length) return;
  const localstorage_cacheAts = JSON.parse(localStorage.getItem('localstorage_cacheAts'));
  const fromPathCacheAts = localstorage_cacheAts[from.path];
  if (!fromPathCacheAts || (fromPathCacheAts && !fromPathCacheAts.includes(to.path))) {
    localstorage_cacheParams[to.path] = {};
  }
  window.localStorage.setItem('localstorage_cacheParams', JSON.stringify(localstorage_cacheParams));
}
