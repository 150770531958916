export default [
    {
        path: '/system/bannerManage',
        name: 'bannerManage',
        component: () => import('@/views/bannerManage/bannerManage.vue'),
        icon: 'a',
        title: 'Banner管理'
    },
    {
        path: '/system/albumManage',
        name: 'albumManage',
        component: () => import('@/views/albumManage/albumManage.vue'),
        icon: 'b',
        title: '专辑管理'
    },
    {
        path: '/system/audioManage',
        name: 'audioManage',
        component: () => import('@/views/audioManage/audioManage.vue'),
        icon: 'b',
        title: '音频管理'
    },
    {
        path: '/system/videoManage',
        name: 'videoManage',
        component: () => import('@/views/vedioManage/videoManage.vue'),
        icon: 'b',
        title: '视频管理'
    },
    {
        path: '/system/branchManage',
        name: 'branchManage',
        component: () => import('@/views/branchManage/branchManage.vue'),
        icon: 'b',
        title: '机构管理'
    },
    {
        path: '/system/imageTextManage',
        name: 'imageTextManage',
        component: () => import('@/views/imageTextManage/imageTextManage.vue'),
        icon: 'c',
        title: '图文管理'
    },
    {
        path: '/system/categoryManage',
        name: 'categoryManage',
        component: () => import('@/views/categoryManage/categoryManage.vue'),
        icon: 'c',
        title: '分类管理'
    },
    {
        path: '/system/tagManage',
        name: 'tagManage',
        component: () => import('@/views/tagManage/tagManage.vue'),
        icon: 'c',
        title: '标签管理'
    },
    // {
    //     path: '/system/itemManage',
    //     name: 'itemManage',
    //     component: () => import('@/views/itemManage/itemManage.vue'),
    //     icon: 'c',
    //     title: '机构管理'
    // },

    {
        path: '/system/itemManage',
        name: 'quite',
        // component: () => import('@/views/itemManage/itemManage.vue'),
        icon: 'd',
        title: '退出登录',
        action: 'logout',
        noAction: true
    },


]
