import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import { useBeforeEach } from './routerInterceptor'
import system from './system.js'
// console.log(system)

const router = createRouter({
  // history: createWebHistory(import.meta.env.BASE_URL),
  history: createWebHashHistory(),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login/login.vue')
    },
    {
      path: '/',
      redirect: '/system/bannerManage'
    },
    {
      path: '/system',
      name: 'system',
      component: () => import('@/components/common/layout.vue'),
      children: system
    },
    { path: "/:catchAll(.*)", redirect: "/system/bannerManage" },
  ]
})

useBeforeEach(router)

export default router
