
import { defineStore } from 'pinia'

import system from '@/router/system.js'


export const useMenuStore = defineStore('menu', {
  state: () => {
    return {
      currentPath: '',
      menuConfig: system,

    }
  },
  getters: {
    menuIndex() {
      if (this.currentPath) {
        return this.menuConfig.findIndex(item => item.path == this.currentPath)
      }
      return null
    },
    guide() { // 面包屑
      if (typeof this.menuIndex === 'number') {
        return this.menuConfig[this.menuIndex].title
      }
      return ''
    }
  },
  actions: {
    set_menuConfig(cf) {
      this.menuConfig = cf
    },
    set_currentPath(path) {
      this.currentPath = path
    },

  },
})

const userInfo = 'userInfo'

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      [userInfo]: null,
      branchList: null,
      branchId: null,
      branchIdChangeByClick: false,
      cardType: 0
    }
  },

  actions: {
    set_userInfo(info) {
      this[userInfo] = info;
      window.localStorage.setItem(userInfo, JSON.stringify(info))
    },
    clear_userInfo() {
      this[userInfo] = null;
      window.localStorage.removeItem(userInfo);
    },
    set_branchList(list) {
      this.branchList = list
    },
    set_branchId(id) {
      this.branchId = id
    },
    set_branchIdChangeByClick(f) {
      this.branchIdChangeByClick = f
    },
    set_cardType(type) {
      this.cardType = type
    }
  }
})
